import angular from 'angular';
import immutable from 'seamless-immutable';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import find from 'lodash/find';

import * as dossierKeys from '@skryv/bundle/customizations/constants/dossierDefinitionKeys';

import { fetchAllDossierDefinitions } from '@skryv/core-ng1/core/store/actions/dosdefs';
import { createDossier } from '@skryv/core-ng1/core/store/actions/dossiers';
import { selectAllDossierDefinitions } from '@skryv/core-ng1/core/store/selectors/dosdefs';

import template from './NewRaiFraude.html';

const namespace = '@skryv/core-ng1/components/dossier/NewRaiFraude';

angular
  .module(namespace, [])
  .component('vekaNewRaiFraude', {
    require: 'ngDialog',
    template,
    controller: function ($ngRedux, $state, $element, $timeout, notifications) {
      'ngInject';

      this.dialog = $element.controller('ngDialogController');
      const disconnect = $ngRedux.connect(mapStateToThis, { fetchAllDossierDefinitions, createDossier })(this);

      this.$onInit = () => {
        this.fetchAllDossierDefinitions();
        this.isAddingDossier = false;
      };

      this.$onDestroy = disconnect;

      this.submit = function () {
        if(!this.types) {
          notifications.error('Something went wrong');
          return;
        }

        const dossierType = find(this.types, { key: dossierKeys.RAI_FRAUDE_DOSSIER });
        if(!dossierType) {
          notifications.error('Something went wrong');
          return;
        }

        this.isAddingDossier = true;

        this.createDossier('test label', dossierType.id) // TODO label will be generated automatically in WVL-562 and can then be removed here
          .then(({ api }) => {
            const dossierId = get(api, 'response.data.id');

            notifications.info('Dossier created');

            $state.go('dossier', { dossierId })
              .then(() => {
                this.isAddingDossier = false;
                if (this.dialog) {
                  return this.dialog.closeThisDialog();
                }
              })
          });
      };
    }
  });

function mapStateToThis(state) {
  return {
    types: sortBy(immutable.asMutable(selectAllDossierDefinitions(state), { deep: true }), 'label')
  };
}

export { template };
export default namespace;
