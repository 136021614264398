import skryv from '@skryv/core-ng1';
import { navigation, translation, theme } from '@skryv/core-ng1';
import vekaCustomizations from './customizations';
import logo from './theme/assets/images/logo.png';

const skrPages = skryv.constants.navigation.pageNames;

theme
  .logo(logo, 'VEKA');

translation
  .locale('nl_BE');

navigation
  .page(skrPages.DASHBOARD, {
    extend: true,
    views: {
      actions: 'vekaDashboardActions'
    }
  })
  .page(skrPages.DASHBOARD_DOSSIERS, {
    extend: true,
    views: {
      actions: 'vekaDashboardActions'
    }
  })
  .page('dossier', {
    extend: true,
    views: {
      content: 'vekaDossier'
    }
  });

skryv
  .customize(theme)
  .customize(vekaCustomizations)
  .customize(translation)
  .customize(navigation)
  .initialize(document.getElementById('root'));

